import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#202945',
            secondary: '#8F993E',
            accent: '#FDCF85',
            error: '#B12028',
          },
        },
      }
});
