const state = {
    // ------------ development -------------
    httpURL: process.env.VUE_APP_httpURL,

    oficiosURL: process.env.VUE_APP_oficiosURL,
    oficiosRedirect: process.env.VUE_APP_oficiosRedirect,

    presupuestoURL: process.env.VUE_APP_presupuestoURL,
    presupuestoRedirect: process.env.VUE_APP_presupuestoRedirect,
    
    sesionesURL: process.env.VUE_APP_sesionesURL,
    sesionesRedirect: process.env.VUE_APP_sesionesRedirect,

    eleccionesURL:  process.env.VUE_APP_eleccionesURL,
    eleccionesRedirect: process.env.VUE_APP_eleccionesRedirect,

    sessionToken: null,
    codigoUsuario: null,
    userName: null,
    userAdmin: false,
    userTitle: 'Unknown',
    mainCardTitle: 'Cargando aplicación...',
    appName: 'Secretaría General',
    role: 'user',
    userId: null,
    systemVersion: '1.0'
}

const getters = {
    httpURL: state => { return state.httpURL; },
    oficiosURL: state => { return state.oficiosURL; },
    oficiosRedirect: state => { return state.oficiosRedirect; },
    presupuestoURL: state => { return state.presupuestoURL; },
    presupuestoRedirect: state => { return state.presupuestoRedirect; },
    sesionesURL: state => { return state.sesionesURL; },
    sesionesRedirect: state => { return state.sesionesRedirect; },
    eleccionesURL: state => { return state.eleccionesURL; },
    eleccionesRedirect: state => { return state.eleccionesRedirect; },
    condonacionesURL: state => { return state.condonacionesURL; },
    condonacionesRedirect: state => { return state.condonacionesRedirect; },

    sessionToken: state => {
        return state.sessionToken;
    },
    codigoUsuario: state => {
        return state.codigoUsuario;
    },
    userName: state => {
        return state.userName;
    },
    userAdmin: state => {
        return state.userAdmin;
    },
    userTitle: state => {
        return state.userTitle;
    },
    mainCardTitle: state => {
        return state.mainCardTitle;
    },
    appName: state => {
        return state.appName;
    },
    role: state => {
        return state.role;
    },
    userId: state => {
        return state.userId;
    },
    systemVersion: state => {
        return state.systemVersion;
    }
}

const actions = {
    setSessionToken(context, payload) {
        context.commit('setSessionTokenHandler', payload);
    },
    setCodigoUsuario(context, payload) {
        context.commit('setCodigoUsuarioHandler', payload);
    },
    setUserAdmin(context, payload) {
        context.commit('setUserAdminHandler', payload);
    },
    setUserName(context, payload) {
        context.commit('setUserNameHandler', payload);
    },
    setUserTitle(context, payload) {
        context.commit('setUserTitleHandler', payload);
    },
    setMainCardTitle(context, payload) {
        context.commit('setMainCardTitleHandler', payload);
    },
    setAppName(context, payload) {
        context.commit('setAppNameHandler', payload);
    },
    setRole(context, payload) {
        context.commit('setRoleHandler', payload);
    },
    setUserId(context, payload) {
        context.commit('setUserIdHandler', payload);
    },
    setSystemVersion(context, payload) {
        context.commit('setSystemVersionHandler', payload);
    }
}

// mutations
const mutations = {
    setSessionTokenHandler(state, token) {
        state.sessionToken = token;
    },
    setCodigoUsuarioHandler(state, codigoUsuario) {
        state.codigoUsuario = codigoUsuario;
    },
    setUserAdminHandler(state, name) {
        state.userAdmin = name;
    },
    setUserNameHandler(state, name) {
        state.userName = name;
    },
    setUserTitleHandler(state, title) {
        state.userTitle = title;
    },
    setMainCardTitleHandler(state, title) {
        state.mainCardTitle = title;
    },
    setAppNameHandler(state, appName) {
        state.appName = appName;
    },
    setRoleHandler(state, role) {
        state.role = role;
    },
    setUserIdHandler(state, userId) {
        state.userId = userId;
    },
    setSystemVersionHandler(state, systemVersion) {
        state.systemVersion = systemVersion;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}