export default {

    install(Vue) {

        Vue.prototype.$notificarError = (error) => {
            let errorData = getErrorData();
            errorData.text = error.message;
            Vue.notify(errorData);
        }

    }
}

function getErrorData() {
    return {
        group: "gral_notif",
        type: "error",
        text: ''
    }
}